<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="shelfinfo" :title="$t('Shelf Info')">
            <b-row>
              <b-col cols="12">
                <b-form-group :label="`*` + $t('Warehouse') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Warehouse"
                    rules="required"
                  >
                    <treeselect
                      v-model="model.warehouse_id"
                      :multiple="false"
                      :clearable="true"
                      :searchable="true"
                      :open-on-click="true"
                      :clear-on-select="true"
                      :show-count="true"
                      :options="warehouses"
                      :limit="5"
                      :max-height="400"
                      :placeholder="$t('Select the warehouse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Shelf code') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Shelf code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.code"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Shelf code')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Name') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Location in the warehouse') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Place"
                  >
                    <b-form-input
                      v-model="model.location"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Location in the warehouse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Tag') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Tag"
                  >
                    <treeselect
                      v-model="model.cat_slug"
                      :multiple="true"
                      :clearable="true"
                      :searchable="true"
                      :open-on-click="true"
                      :clear-on-select="true"
                      :show-count="true"
                      :options="items"
                      :limit="5"
                      :max-height="400"
                      @input="handlerChangeCategory"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Floors') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Floors"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.floor"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Floors')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Quantity') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.quantity"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Quantity')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group :label="$t('QR code')+`:`" label-cols-md="12">
                  <!-- <span @click="isQrCode=true" class="text-success">Tạo QR code</span> -->
                  <div style="height: 200px;">
                    <vrcode
                      v-if="isQrCode"
                      :download="{
                        text: 'Download',
                        filename: 'file-name.png',
                        visible: true, type: 'image/png'
                      }"
                      :value="model.code"
                      :options="{
                        size: 180,
                        level: 'Q'
                      }"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <!-- <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset> -->
            <Fieldset id="status" :title="$t('Status')">
              <!-- <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  :placeholder="$t('Position')"
                />
              </b-form-group> -->
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>

              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
// import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import Treeselect from '@riophae/vue-treeselect'
import vrcode from '@ispa.io/vrcode'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    // vSelect,
    Treeselect,
    vrcode,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      avatarText,
      required,
      warehouses: [
        {
          id: '1',
          label: 'Kho mặc định',
        },
      ],
      model: {
        name: null,
        qrcode: null,
        code: null,
        location: null,
        floor: null,
        tag: null,
        quantity: null,
        is_active: true,
        storage_id: '1',
      },
      items: [],
      isQrCode: false,
      timeout: null,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'model.code': function () {
      this.isQrCode = false
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.isQrCode = true
      }, 10)
    },
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.slug,
              label: x.name,
              slug: x.slug,
              idx: x.id,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.slug,
                  label: y.name,
                  slug: y.slug,
                  idx: y.id,
                }
                item.children.push(i)
              })
            }
            this.items.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              storage_id: this.model.warehouse_id,
              name: this.model.name,
              code: this.model.code,
              quantity: this.model.quantity,
              location: this.model.location,
              floor: this.model.floor,
              is_active: Number(this.model.is_active === true ? 1 : 0),
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/store_shelve`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/shelf/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.box-list {
  margin-top: 2rem;
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
